import instrumentation from '../../../common/microfrontend/instrumentation-api'
import communication from '../../../common/microfrontend/communication-api'
import freezeVersionApi from '../../../common/microfrontend/freeze-version-api'
import { libs } from '../../../core/backbone'
import getMfeLoader from '../../../common/microfrontend/loaders'

const referenceBag = libs()

export default {
  instrumentation,
  communication,
  freezeVersion(mfe) {
    return freezeVersionApi(mfe, this.configuration)
  },
  configure: function configure(configuration) {
    this.configuration = {
      ...configuration,
    }

    referenceBag.timeout = (configuration.assets || {}).timeout || 12000
  },
  /**
   *
   * @param {string} name - The MFE name. Can be "mfe-name" or "mfe-name@1.0.1" for example.
   * @param {function} callback - callback to render the micro frontend
   * @param {object} options - The function options
   * @param {function} options.errorCallback - a callback to be called when an error occurs
   * @param {('high'|'medium')} options.priority - the priority that the micro frontend will be loaded
   */
  async requireMicrofrontend(name, callback, options = {}) {
    const { errorCallback } = options

    const originalOnError = window.onerror
    window.onerror = (message, source, lineno, colno, error) => {
      typeof errorCallback === 'function'
        ? errorCallback(error)
        : console.error(error)
      originalOnError?.apply(this, arguments)
    }

    const loaderOptions = {
      name,
      callbacks: { onLoadDependency: callback },
      options,
      // this.configuration should be removed only when all microfrontends were migrated.
      configuration: this.configuration,
    }

    try {
      const loader = getMfeLoader(loaderOptions)
      await loader()
    } catch (err) {
      typeof errorCallback === 'function'
        ? errorCallback(err)
        : console.error(err)
    }
  },
}
