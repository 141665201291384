import { NAMESPACE } from '../constants'

const scope = typeof window !== 'undefined' ? window : global
const instance = scope[NAMESPACE] || (scope[NAMESPACE] = {})
const memoised = {}

function get(name, _default = {}) {
  if (Object.prototype.hasOwnProperty.call(instance, name)) {
    memoised[name] = instance[name]
  } else if (Object.prototype.hasOwnProperty.call(memoised, name)) {
    instance[name] = memoised[name]
  } else {
    memoised[name] = _default
    instance[name] = memoised[name]
  }
  return memoised[name]
}

export const application = () => instance

export const manifest = () => get('manifests')

export const libs = () => get('libs')

export const communication = () => get('communication')

export const services = () => get('services', [])

export const history = () => get('history')

export const instrumentation = () => get('instrumentation', [])
