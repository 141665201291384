/**
 * The requestIdleCallback has the responsibility of setting the best time to trigger the
 * tasks in the browser event loop, with low priority and without affecting the browser's performance.
 * The Performance API is also being used to measure the cost of each triggered idle callback.
 */
export const requestIdleCallback =
  window.requestIdleCallback ||
  function (handler) {
    let startTime = Date.now()
    return setTimeout(function () {
      handler({
        didTimeout: false,
        timeRemaining: function () {
          return Math.max(0, 50.0 - (Date.now() - startTime))
        },
      })
    }, 1)
  }

export const cancelIdleCallback =
  window.cancelIdleCallback ||
  function (id) {
    clearTimeout(id)
  }
