import { libs } from '../../../core/backbone'
import FronthubError from '../../../core/helpers/FronthubError'
const referenceBag = libs()

/**
 * Create a link element for the asset url and inject it on document head
 * @param {string} asset - CSS href
 * @returns HTMLLinkElement
 */
export function loadCssDependency(asset) {
  const link = document.createElement('link')
  const error = new FronthubError()

  /* eslint-disable no-inner-declarations */
  function onLinkComplete(event) {
    // avoid mem leaks in IE.
    link.onerror = null
    link.onload = null
    // eslint-disable-next-line no-use-before-define
    clearTimeout(timeout)
    if (event.type === 'load') return
    const errorType = event && event.type
    const realSrc = event && event.target && event.target.href
    error.message = `Loading ${asset} failed.\n(${errorType}: ${realSrc})`
    error.type = errorType
    error.request = realSrc
    throw error
  }

  link.type = 'text/css'
  link.rel = 'stylesheet'
  link.href = asset

  const timeout = setTimeout(function timeout() {
    onLinkComplete({ type: 'timeout', target: link })
  }, referenceBag.timeout || 12000)

  link.onerror = onLinkComplete
  link.onload = onLinkComplete

  return document.head.appendChild(link)
}

/**
 * Check if the asset is lazy load considering the deprecated props initialLoad
 * Note: The property `initialLoad` is @deprecated in v5.0.0
 * @param {object} asset
 * @returns Boolean
 */
const isLazyLoading = asset =>
  asset?.lazyLoad ??
  (asset.hasOwnProperty('initialLoad') && asset.initialLoad === false)

/**
 * Create stylesheet elements for css assets which should initial load.
 * Then it will be appended it on document head.
 * Initial load means that the file should be loaded during the document rendering.
 * @param {object} frontHubContext - {manifestInstance}
 */
export default function cssDependencyLoader({ manifestInstance }) {
  const assets = manifestInstance.assets || []

  const cssAssets = assets.filter(asset => {
    const isCssFile = /\.css$/.test(asset.path)

    // A fallback to webpack loading. Assuring that css dependency is loaded at browser with SPA event route change.
    const isCssAndIsLoaded =
      isCssFile &&
      document.querySelectorAll(`link[href*="${asset.path}`).length > 0

    return isCssFile && (isLazyLoading(asset) === false || !isCssAndIsLoaded)
  })

  cssAssets.map(asset =>
    loadCssDependency(manifestInstance.rootPath(asset.path)),
  )
}
