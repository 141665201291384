import { communication } from '../../../core/backbone'
import FronthubError from '../../../core/helpers/FronthubError'
import { parseToSafeJson } from '../../../core/security'
import normalizeEvent from './normalizeEvent'

const communicationContext = communication()

function isValidPayload(payload) {
  return !Array.isArray(payload) && typeof payload === 'object'
}

export default function emit(message, payload) {
  if (payload && !isValidPayload(payload)) {
    throw new FronthubError(
      'if you want to use the payload param, it must be an object',
    )
  }

  const event = normalizeEvent(message)

  const callbacks = communicationContext[event] || []

  callbacks.forEach(callback => {
    try {
      callback(parseToSafeJson(payload))
    } catch (e) {
      const [channel, message] = event.split(':')

      if (callback.microfrontendName) {
        console.error(
          'FrontHub: Micro frontend ' +
            callback.microfrontendName +
            ' message ' +
            message +
            ' channel ' +
            channel +
            ' error ' +
            e,
        )
      } else {
        console.error(
          'FrontHub: Application host message ' +
            message +
            ' channel ' +
            channel +
            ' error ' +
            e,
        )
      }
    }
  })
}
