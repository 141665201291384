import { communication } from '../../../core/backbone'
import normalizeEvent from './normalizeEvent'

const communicationContext = communication()

function register(event, callback) {
  const callbacks = communicationContext[event] || []
  const callbackExists = callbacks.indexOf(callback) !== -1

  if (callbackExists) return

  communicationContext[event] = [...callbacks, callback]
}

function unregister(event, callback) {
  const callbacks = communicationContext[event] || []
  const indexOfCallback = callbacks.indexOf(callback)
  const callbackExists = indexOfCallback !== -1

  if (!callbackExists) return

  communicationContext[event].splice(indexOfCallback, 1)
}

export default function listen(message, callback) {
  const event = normalizeEvent(message)

  register(event, callback)

  return () => unregister(event, callback)
}
