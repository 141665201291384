import { getScriptSrc } from '../../../core/assets'

export function preloadDependency(asset) {
  const link = document.createElement('link')
  link.rel = 'preload'
  link.crossOrigin = 'anonymous'
  link.href = asset
  link.as = as(asset)
  return document.head.appendChild(link)
}

function as(asset) {
  if (/\.css$/.test(asset)) {
    return 'style'
  } else if (/\.js$/.test(asset)) {
    return 'script'
  } else {
    return undefined
  }
}

/**
 * Create links elements as preload to requesting resources ahead of time
 * @param {object} fronthubContext - Entire FrontHub Context
 * @returns void
 */
export function preloadCommons(fronthubContext = {}) {
  const { manifestInstance = {} } = fronthubContext

  if (!manifestInstance.commons) return
  if (!manifestInstance.commons.preload) return
  if (!Array.isArray(manifestInstance.commons.preload)) return

  manifestInstance.commons.preload.forEach(lib => {
    const assetUrl = getScriptSrc(fronthubContext, lib)

    if (!!document.querySelectorAll(`[href="${assetUrl}"]`).length) return

    return preloadDependency(assetUrl)
  })
}
