import { instrumentation } from '../../../core/backbone'
import {
  InstrumentationTypeNames,
  InstrumentationTypes,
} from './instrumentation-types'
import { LogLevelNames } from './log-levels'

const listeners = instrumentation()

export default function (configuration = {}) {
  const idx = []

  InstrumentationTypeNames.forEach(type => {
    const callback = configuration[type]
    if (typeof callback === 'function') {
      const id = Symbol()
      listeners.push({
        id,
        callback,
        type,
        listenerName: type,
      })
      idx.push(id)
    }
  })

  LogLevelNames.forEach(level => {
    const callback = configuration[level]
    if (typeof callback === 'function') {
      const id = Symbol()
      listeners.push({
        id,
        callback,
        type: InstrumentationTypes.log,
        listenerName: level,
      })
      idx.push(id)
    }
  })

  return () => {
    idx.forEach(id => {
      listeners.splice(
        listeners.findIndex(l => l.id === id),
        1,
      )
    })
  }
}
