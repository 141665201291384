/**
 * This function ensures that the `json` object is a flat object,
 * that is, that it does not have functions as the value of any property.
 *
 * @param {object} json Object to be parse
 * @returns The parsed object
 */
export function parseToSafeJson(json) {
  if (typeof json === 'undefined') return undefined
  return JSON.parse(JSON.stringify(json))
}
