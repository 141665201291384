import { dispatch } from '../instrumentation-api/core'
import { InstrumentationTypes } from '../instrumentation-api/instrumentation-types'

const PREFIX = '@fronthub'

export const freezeVersionStorage = {
  get(name) {
    const result = sessionStorage.getItem(`${PREFIX}/${name}`)
    return { ...JSON.parse(result) }
  },

  set(mfe, configuration) {
    const [name, version] = mfe.split('@')

    if (!version || !name) {
      return 'Nothing to freeze.'
    }

    dispatch(
      {
        type: InstrumentationTypes.track,
        name: `[FrontHub] freezeVersion command`,
        payload: {
          mfeName: name,
          mfeVersion: version,
        },
        options: {},
      },
      configuration,
    )

    sessionStorage.setItem(`${PREFIX}/${name}`, JSON.stringify({ version }))
    setTimeout(() => {
      window.location.reload()
    }, 2000)

    return `🥶 MFE ${name} freezed with version "${version}". Reloading this web page to load the freezed version in 2 seconds.`
  },
}

export function freezeVersion(mfe, configuration) {
  if (!mfe) return `It's required a "mfe-name@version" to freeze a version.`

  return freezeVersionStorage.set(mfe, configuration)
}
