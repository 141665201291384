function FronthubError() {
  const [message, ...rest] = arguments
  const instance = new Error([message, ...rest])
  instance.name = 'FrontHub'
  if (typeof Error.captureStackTrace === 'function') {
    Error.captureStackTrace(instance, FronthubError)
  }
  return instance
}

FronthubError.prototype = Object.create(Error.prototype, {
  constructor: {
    value: Error,
    enumerable: false,
    writable: true,
    configurable: true,
  },
})

module.exports = FronthubError
