export const LogLevels = {
  verbose: 1,
  debug: 2,
  info: 3,
  warn: 4,
  error: 5,
}

export const LogLevelValues = Object.values(LogLevels)

export const LogLevelNames = Object.keys(LogLevels)

/**
 * Default level used in log() dispatch
 */
export const LogLevelDefaultName = 'info'

export const getLevelNumber = name => {
  const entries = Object.entries(LogLevels)

  for (let i = 0; i < entries.length; i++) {
    if (entries[i][0] === name) {
      return entries[i][1]
    }
  }

  return LogLevels.verbose
}
