import { getScriptSrc } from '../../../core/assets'
import { libs, application } from '../../../core/backbone'

import requireScript from './require-script'

const libraries = libs()
const microfrontend = application()

export default async function bootstrapDependencies(
  fronthubContext,
  library,
  connect,
  factory,
  dependencies,
) {
  const { manifestInstance } = fronthubContext
  const promises = dependencies.map(async name => {
    if (Object.hasOwnProperty.call(libraries, name)) {
      const libraryReference = libraries[name]
      /* we already had this on page */
      if (libraryReference[0] === 0 /* already resolved */) {
        return libraryReference[1] /* resolved promise */
      } /* still loading */
      return libraryReference[2] /* pending promise */
    }
    const scriptSrc = getScriptSrc(fronthubContext, name)
    const resolve = await requireScript(scriptSrc, name)
    return resolve
  })
  const depsInstances = [...(await Promise.all(promises))]
  if (!manifestInstance.isCommonsDependency(library)) {
    microfrontend.connect = connect
  }
  const instance = factory(...depsInstances)
  delete microfrontend.connect
  return instance
}
