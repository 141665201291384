import FronthubError from './helpers/FronthubError'

export function getScriptSrc(fronthubContext, name) {
  const { manifestInstance, configuration } = fronthubContext

  if (!manifestInstance) {
    throw new FronthubError('can not be undefined')
  }

  const staticPathCommons =
    (configuration.commons || {}).path || 'static/commons/'
  const isCommonsDependency = manifestInstance.isCommonsDependency(name)
  if (isCommonsDependency) {
    return `${manifestInstance.repository}/${staticPathCommons}${name}.js`
  }
  return manifestInstance.path
}
