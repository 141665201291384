import FronthubError from '../../../core/helpers/FronthubError'
import listen from './listen'
import emit from './emit'

const ALLOWED_OPTIONS = ['listen', 'emit']

const allowed = ALLOWED_OPTIONS.join(' or ')

export default function communication(option, ...rest) {
  if (!ALLOWED_OPTIONS.includes(option)) {
    throw new FronthubError(
      `only ${allowed} are accepted as option, but we received "${option}"`,
    )
  }

  return { listen, emit }[option](...rest)
}
