/* eslint-disable import/prefer-default-export */
import { fromConfiguration } from '../../../production/application-host/manifest'
import dependencyLoader from '../../../production/application-host/dependency-loader/dependency-loader'
import cssDependencyLoader from '../../../production/application-host/dependency-loader/css-dependency-loader'
import { preloadCommons } from '../../../production/application-host/dependency-loader/preload-dependency'
import { getScriptSrc } from '../../../core/assets'
import requireScript from '../../../production/application-host/dependency-loader/require-script'
import { freezeVersionStorage } from '../freeze-version-api'

const READY_STATE_COMPLETE = 'complete'

const loadMFE = async (name, callbacks, configuration) => {
  const { onLoadManifest, onLoadDependency } = callbacks
  const manifestInstance = await fromConfiguration(configuration, name)
  onLoadManifest?.(manifestInstance)

  const fronthubContext = {
    manifestInstance,
    configuration,
  }

  dependencyLoader(fronthubContext, connect => {
    const instance = connect.call(
      undefined,
      Object.assign(configuration, fronthubContext),
    )
    onLoadDependency.call(this, instance)
    return instance
  })

  preloadCommons(fronthubContext)

  cssDependencyLoader(fronthubContext)

  await requireScript(
    getScriptSrc(fronthubContext, manifestInstance.key),
    manifestInstance.key,
  )
}

function lazyLoadMFE(loader) {
  async function handle(event) {
    if (event.target.readyState === READY_STATE_COMPLETE) {
      return await loader()
    }
  }

  document.addEventListener('readystatechange', handle)
  return
}

function getMfeLoader({ name: nameProp, callbacks, options, configuration }) {
  const [parsedName] = nameProp.split('@')
  const { version } = freezeVersionStorage.get(nameProp)

  const name = version ? `${parsedName}@${version}` : nameProp

  const { priority = 'high' } = options

  const loadFunction = () => loadMFE(name, callbacks, configuration)

  if (document.readyState === READY_STATE_COMPLETE) {
    return loadFunction
  }

  const priorities = {
    high: loadFunction,
    medium: () => lazyLoadMFE(loadFunction),
  }

  return priorities[priority] || priorities.high
}

export default getMfeLoader
