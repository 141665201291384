/** @format */
/* eslint no-param-reassign: 0 */
import { libs } from '../../../core/backbone'
import bootstrapDependency from './bootstrap-dependency'
import FronthubError from '../../../core/helpers/FronthubError'

const globalContext = window || global
const referenceBag = libs()

export default function dependencyLoader(fronthubContext, connect) {
  const { manifestInstance } = fronthubContext
  const originalGlobalDefine = globalContext.define || function noop() {}
  globalContext.define = function fronthubDependencyLoader(
    library,
    dependencies,
    factory,
  ) {
    if (
      !manifestInstance.isCommonsDependency(library) &&
      manifestInstance.key !== library
    ) {
      originalGlobalDefine.call(null, library, dependencies, factory)
      return
    }

    if (Object.prototype.hasOwnProperty.call(referenceBag, library)) {
      const [resolve, cached] = referenceBag[library]
      const instance = bootstrapDependency(
        fronthubContext,
        library,
        connect,
        factory,
        dependencies,
      )
      referenceBag[library] = [0, instance]
      if (typeof resolve === 'function') {
        resolve(instance)
      } else if (typeof cached === 'function') {
        cached(instance)
      } else {
      }
    } else if (typeof originalGlobalDefine === 'function') {
      originalGlobalDefine.call(null, library, dependencies, factory)
    } else {
      /* istanbul ignore next */
      throw new FronthubError(
        `Failed to process ${library}. This is probably a bug inside dependencyLoader.`,
      )
    }
  }
}
