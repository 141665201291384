import { requestIdleCallback } from '../../../core/helpers/polyfill'

const TIMEOUT_DEFAULT = 1000

/**
 * Singletons for managing the task queue
 */
const taskQueue = []
let running = false

function runTaskQueue(deadline) {
  running = true

  while (
    (deadline.timeRemaining() > 0 || deadline.didTimeout) &&
    taskQueue.length
  ) {
    const { handler, payload } = taskQueue.shift()
    handler(payload)
  }

  if (taskQueue.length) {
    requestIdleCallback(runTaskQueue, { timeout: TIMEOUT_DEFAULT })
  } else {
    running = false
  }
}

function enqueueTask(task) {
  taskQueue.push(task)

  if (!running) {
    requestIdleCallback(runTaskQueue, { timeout: TIMEOUT_DEFAULT })
  }
}

export const defer = handler => payload => {
  enqueueTask({
    handler,
    payload,
  })
}

export const getQueueSize = () => taskQueue.length
