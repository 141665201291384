import { manifest } from '../../../core/backbone'
import FronthubError from '../../../core/helpers/FronthubError'

const key = (name, version) => `${name}@${version}`
const manifests = manifest()

function manifestPath(configuration, name) {
  return `${configuration.repository}${
    (configuration.microfrontends || {}).manifest || '/microfrontends'
  }/${name}`
}

export default function manifestFactory(configuration, rawManifest) {
  const { repository } = configuration
  const manifest = {
    repository,
    ...rawManifest,
    key: key(rawManifest.name, rawManifest.version),
    get path() {
      return manifest.rootPath(`${manifest.key}.js`)
    },
    rootPath(file = '') {
      let rootPathBuilder = (m, f) =>
        `${m.repository}/assets/${m.name}/${m.version}/${f}`
      if (
        configuration.microfrontends &&
        configuration.microfrontends.rootPath
      ) {
        rootPathBuilder = configuration.microfrontends.rootPath
        if (typeof rootPathBuilder !== 'function')
          throw new FronthubError(
            'front-hub: configuration.microfrontends.rootPath must be a function',
          )
      }
      return rootPathBuilder.call(null, manifest, file)
    },
    isCommonsDependency(dependencyKey) {
      const commons = rawManifest.commons || {}
      return (commons.dependencies || []).indexOf(dependencyKey) !== -1
    },
  }
  return manifest
}

/**
 * Get the microfrontend manifest according to configurations defined in host application, ex: `fronthub('configure', {})`
 * @param {object} configuration - object defined in host application
 * @param {string} name - microfrontend name containing or not the version, ex: `name or name@version`
 * @returns {object} the microfrontend manifest
 */
export async function fromConfiguration(configuration, name) {
  try {
    const url = manifestPath(configuration, name)
    if (manifests[url]) return manifests[url]

    const response = await fetch(url, {
      method: 'get',
      cache: 'default',
      referrerPolicy: 'no-referrer',
      ...(configuration.microfrontends || {}).fetch,
    })

    const manifest = await response.json()
    manifests[url] = manifestFactory(configuration, manifest)

    return manifests[url]
  } catch (err) {
    const error = new FronthubError()
    error.message = err
    error.type = 'network'
    throw error
  }
}
