/**
 * Used to configure the listeners.
 * The ordering of values matters.
 */
export const InstrumentationTypes = {
  track: 'track',
  log: 'log',
}

export const InstrumentationTypeNames = Object.keys(InstrumentationTypes)
