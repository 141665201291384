import { instrumentation } from '../../../core/backbone'
import { defer } from './callback-manager'
import { InstrumentationTypes } from './instrumentation-types'
import { getLevelNumber } from './log-levels'

export const performanceMark = (payload, debug) => {
  if (!payload.tracePerformance) return () => undefined

  const name = markName(payload)

  if (
    typeof performance !== 'undefined' &&
    typeof performance.mark !== 'undefined'
  ) {
    if (debug) console.info(`Mark ${name} start created`)
    performance.mark(`${name}__start`)
  }

  return () => {
    if (!payload.tracePerformance) return
    if (
      typeof performance !== 'undefined' &&
      typeof performance.mark !== 'undefined'
    ) {
      if (debug) console.info(`Mark ${name} end created`)
      performance.mark(`${name}__end`)
    }
  }
}

const markName = ({ type, options: { group }, metadata: { microfrontend } }) =>
  `${microfrontend.name}__${group}__${type}`

const filterListeners = (listeners, event, configuration) =>
  listeners.filter(listener => {
    if (listener.type === InstrumentationTypes.log) {
      const configNumber = getLevelNumber(
        configuration.log && configuration.log.level,
      )
      const eventNumber = getLevelNumber(event.level)

      if (eventNumber < configNumber) {
        return false
      }

      if (listener.listenerName !== InstrumentationTypes.log) {
        return listener.listenerName === event.level
      }
    }

    return listener.type === event.type
  })

const dispatchToHost = ({ event, configuration }) => {
  const hostListeners = instrumentation()

  if (hostListeners.length === 0) {
    console.log(event)
    return
  }

  const listeners = filterListeners(hostListeners, event, configuration)
  let x = -1
  const next = () => listeners[++x] && listeners[x].callback(event, next)

  try {
    next()
  } catch (e) {
    console.error(e)
  }
}

export const dispatch = (event, configuration, debug = false) => {
  const lazy = defer(signal => {
    signal()
    defer(dispatchToHost)({ event, configuration })
  })
  lazy(performanceMark(event, debug))
}
