/* eslint-disable no-bitwise */
import interopProduction from '.'
import { DEFAULT_FRONTHUB_FUNC_NAME } from '../../../constants'

const commandOrder = {
  configure: 1,
  instrumentation: 2,
  requireMicrofrontend: 4,
  communication: 5,
}

// eslint-disable-next-line no-underscore-dangle
const funcName = window.__fronthub__ || DEFAULT_FRONTHUB_FUNC_NAME
const func = window[funcName] || function func() {}

const queue = []
  .concat(func.q || [])
  .sort(([a], [b]) => commandOrder[a] - commandOrder[b])

queue.forEach(([command, ...args]) =>
  interopProduction[command].call(interopProduction, ...args),
)

window[funcName] = function fronthubBootstrap(command, ...args) {
  return interopProduction[command].call(interopProduction, ...args)
}
