import { libs } from '../../../core/backbone'
import FronthubError from '../../../core/helpers/FronthubError'

const referenceBag = libs()
/**
 * How it works:
 * Each time a script is loaded it calls `define` function that will call `resolve` defined and salved inside referenceBag.
 * Each item of referenceBab can be either:
 *  [0, Resolved Promise]
 * Or
 *  [resolve func, reject func, Pending Promise]
 * A key of reference bag is the name of the library plus its version, something like:
 *  react-dom@16.9.0
 *
 * So when we starting loading a library, for example react version 16.9.0.
 * At first referenceBag will contain:
 * react@16.9.0 => [resolve func, reject func, Pending Promise]
 * Then define function will be called and call `resolve` and change referenceBag to:
 * react@16.9.0 => [0, Resolved Promise]
 *
 */
export default function requireScript(src, key) {
  if (!Object.prototype.hasOwnProperty.call(referenceBag, key)) {
    referenceBag[key] = []
  }
  let scriptReference =
    referenceBag[
      key
    ] /* [resolve, reject, promise] for loading or [0] for loaded */
  let retPromise
  const error = new FronthubError()

  if (scriptReference[0] !== 0) {
    /* not loaded yet */
    if (scriptReference[0]) {
      /* loading */
      retPromise = scriptReference[2 /* promise reference */]
    } else {
      /* first request */
      const script = document.createElement('script')
      /* eslint-disable no-inner-declarations */
      function onScriptComplete(event) {
        // avoid mem leaks in IE.
        script.onerror = null
        script.onload = null
        /* eslint-disable no-use-before-define */
        clearTimeout(timeout)
        const reference = referenceBag[key]
        if (reference[0] !== 0) {
          if (reference[0]) {
            const errorType =
              event && (event.type === 'load' ? 'missing' : event.type)
            const realSrc = event && event.target && event.target.src
            error.message = `Loading ${key} failed.\n(${errorType}: ${realSrc})`
            error.type = errorType
            error.request = realSrc
            reference[1 /* reject */](error)
          }
          delete referenceBag[key]
        }
      }
      const promise = new Promise((resolve, reject) => {
        scriptReference = [resolve, reject]
        referenceBag[key] = scriptReference
      })

      retPromise = promise
      scriptReference[2] = promise
      /* increase parsing and execution priority */
      script.async = true
      script.crossOrigin = 'anonymous'
      script.src = src

      const timeout = setTimeout(function timeout() {
        onScriptComplete({ type: 'timeout', target: script })
      }, referenceBag.timeout || 12000)

      script.onerror = onScriptComplete
      script.onload = onScriptComplete
      document.head.appendChild(script)
    }
  } else {
    retPromise = scriptReference[1] /* resolved promise */
  }
  return retPromise
}
